ymaps iframe {
	display: none;
}
div.balloon {
	position: absolute;
	border-radius: 5px;
	background-color: #FFF;
	padding: 12px;
	box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
	font-size: 12px;
	line-height: 20px;
}
div.balloon > div.arrow {
	position: absolute;
	display: block;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
	border-width: 11px;
	bottom: -11px;
	left: 50%;
	margin-left: -11px;
	border-top-color: #FFF;
	border-bottom-width: 0;
}
div.balloon div.button {
	position: absolute;
	right: 6px;
	bottom: 5px;
	padding: 8px;
	width: 18px;
	height: 18px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	user-select: none;
}
div.balloon div.button > div.circle {
	width: 4px;
	height: 4px;
	border-radius: 2px;
	background-color: #22B0E0;
}
div.placemark-block {
	position: absolute;
	bottom: -16px;
	left: 14px;
	width: 90px;
	height: 55px;
	background: #FFF;
	border: 1px solid #C77359;
	box-sizing: border-box;
	border-radius: 5px;
	padding: 7px;
	/*opacity: 0.3;*/
}
.placemark-block p {
	font-family: 'Roboto', sans-serif;
	font-weight: bold;
	font-size: 12px;
	line-height: 14px;
	text-align: right;
	margin: 0px;
	white-space: nowrap;
}
.placemark-block p.pdz {
	color: #E51A1A;
}
.placemark-block p.sum {
	font-weight: initial;
}
.placemark-block p.tasks {
	color: #A8A8A8;
}
.placemark-block p .opened {
	color: #E51A1A;
}
.placemark-block p .closed {
	color: #129C8B;
}
.placemark-block p .rub {
	color: #A8A8A8;
	font-weight: initial;
}
.placemark-block p img.camera {
	width: 10px;
	height: 10px;
}
.initials {
	position: absolute;
	width: 28px;
	height: 28px;
}
.position.initials {
	width: 37px;
	height: 17px;
	overflow: hidden;
}
.initials span {
	height: 28px;
	width: 28px;
	font-family: 'Roboto', sans-serif;
	font-weight: bold;
	font-size: 12px;
	line-height: 14px;
	color: white;
	vertical-align: middle;
	display: table-cell;
	text-align: center;
}
.initials.base span {
	color: black;
}
.position.initials span {
	width: 30px;
	height: 18px;
	font-size: 9px;
}
div.status {
	position: absolute;
	top: -3px;
	left: -2px;
	background-color: #FFF;
	border-radius: 6px;
	width: 12px;
	height: 12px;
	box-sizing: border-box;
}
div.status div {
	box-sizing: border-box;
	position: relative;
	top: 3px;
	left: 3px;
	background-color: #FF2E00;
	border-radius: 3px;
	width: 6px;
	height: 6px;
}
div.status div.visited {
	background-color: #FFD600;
}
div.status div.nearby {
	background-color: #53AE82;
}
div.cluster {
	border-radius: 30px;
	width: 60px;
	height: 60px;
	background-color: #FFFFFF;
	border: 2px solid #22B0E0;
	box-sizing: border-box;
}
div.cluster span {
	width: 60px;
	height: 60px;
	font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;
	color: #000;
	text-align: center;
	vertical-align: middle;
	display: table-cell;
}
div.clusterPDZ {
	position: absolute;
	left: 45px;
	top: 10px;
	border-radius: 20px;
	width: 40px;
	height: 40px;
	background-color: #E51A1A;
	box-sizing: border-box;
}
div.clusterPDZ span {
	width: 40px;
	height: 40px;
	font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	color: #FFF;
	text-align: center;
	vertical-align: middle;
	display: table-cell;
}
